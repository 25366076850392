<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
import BaseButton from '@/shared/components/BaseButton.vue';
import PdfQuestionCard from '@/shared/cards/PdfQuestionCard.vue';
import AddQuestionToPdf from '@/shared/components/AddQuestionToPdf.vue';

Vue.use(VueChatScroll);

const store = {
  state: {
    loading: false,
    pdf: true,
    file_name: '',
    evaluation_list: [],
    question_selected: null,
    file_id: 0,
  },
};
export default {
  components: {
    BaseButton,
    PdfQuestionCard,
    AddQuestionToPdf,
  },
  data() {
    return {
      message: '',
      store,
    };
  },
  methods: {
    getFile() {
      const getFilePath = '/WebOpenaiGetPdfDetails.php';
      const data = new FormData();
      data.append('file_id', this.$route.params.id);

      fetch(server + getFilePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.name !== '') {
          store.state.file_id = json.id;
          store.state.file_name = json.name;
          store.state.evaluation_list = json.evaluation_list;
        }
      });
    },
    openAddQuestion() {
      const addQuestionWindow = document.querySelector('.view-item');
      addQuestionWindow.classList.toggle('show');
      if (addQuestionWindow.classList.value.includes('hide')) {
        addQuestionWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addQuestionWindow.classList.toggle('hide');
        }, 300);
      }
    },
    addQuestion(item) {
      setTimeout(() => {
        store.state.question_selected = item;
      }, 300);
      this.openAddQuestion();
    },
    removePdfEvaluation(question) {
      const deletePath = '/WebOpenaiRemovePdfEvaluation.php';
      const data = new FormData();
      data.append('id', question.id);
      fetch(server + deletePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          this.getFile();
        }
      });
    },
  },
  beforeMount() {
    this.getFile();
  },
};
</script>
<template lang="html">
  <div class="page-container">
    <div class="title-card">
      <b-row>
        <b-col md="6">
         <div class="basic-1 headline-bold-16"
         style="display: flex; height: 100%;
          vertical-align: center; align-items: center"
         > {{ $t('FILE_AI.QUESTIONS_LIST') }} </div>
        </b-col>
        <b-col md="6" style="padding-right: 0">
          <BaseButton style="float: right"

                      :callback="addQuestion"
                      :callback-params="0">
            <unicon name="plus" width="16" height="16"
                    style="margin-right: 12px;margin-top: -2px" fill="white"/>
            {{ $t('FILE_AI.ADD_QUESTION') }}
          </BaseButton>
        </b-col>
      </b-row>
    </div>
    <div class="questions-container">
      <div v-for="(item,i) in store.state.evaluation_list" :key="i">
        <PdfQuestionCard
            :question="item"
            :callback="addQuestion"
            :callback-params="item"
            :callback2="removePdfEvaluation"
            :callback-params2="item"
        />
      </div>
    </div>
    <div class="view-item hide">
      <AddQuestionToPdf :key="store.state.question_selected && store.state.question_selected ?
     store.state.question_selected.id : 0"
               :info="store.state.question_selected ? store.state.question_selected : {}"
               :id="store.state.question_selected ? store.state.question_selected.id : 0"
               :callback="() => {getFile()}"
                        :fileId="store.state.file_id"
      >
      </AddQuestionToPdf>
    </div>
  </div>
</template>
<style lang="scss">
.title-card{
  background-color: #1694D01A;
  border-radius: 8px;
  padding: 4px 4px 4px 12px;
  margin-bottom: 8px;
}
.title-card .title{
}
</style>
