<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddCardModel from '@/modules/tags/add-card-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import i18n from '../plugins/vue-i18n';

const { fields } = AddCardModel;
const store = {
  state: {
    answer: '',
    title: '',
  },
};
export default {
  name: 'AddQuestionToPdf',
  components: {
    BaseButton,
  },
  props: {
    info: {
    },
    fileId: {

    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      store,
      model: {},
      loading: false,
      id: null,
    };
  },
  methods: {
    Notify() {
      return Notify;
    },
    openAddQuestion() {
      const addQuestionWindow = document.querySelector('.view-item');
      addQuestionWindow.classList.toggle('show');
      if (addQuestionWindow.classList.value.includes('hide')) {
        addQuestionWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addQuestionWindow.classList.toggle('hide');
        }, 300);
      }
    },
    addQuestionFunc(e) {
      e.preventDefault();
      this.openAddQuestion();
      let addQuestionPath = '';
      const data = new FormData();
      if (this.id && parseInt(this.id, 10) !== 0) {
        addQuestionPath = '/WebOpenaiEditPdfEvaluation.php';
        data.append('id', this.id);
      } else {
        addQuestionPath = '/WebOpenaiAddPdfEvaluation.php';
        data.append('id', this.fileId);
      }
      data.append('name', store.state.title);
      data.append('answer', store.state.answer);

      fetch(server + addQuestionPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
            if (json.status === 'ok') {
              this.callback();
            }
          }
        });
    },
  },
  beforeMount() {
    if (this.info && this.id !== this.info.id) {
      store.state.title = this.info.name;
      this.id = this.info.id;
      store.state.answer = this.info.answer;
    } else if (this.info === 0) {
      store.state.title = '';
      this.id = 0;
      store.state.answer = '';
    }
  },
  beforeUpdate() {
    if (this.info && this.id !== this.info.id) {
      store.state.title = this.info.name;
      this.id = this.info.id;
      store.state.answer = this.info.answer;
    } else if (this.info === 0) {
      store.state.title = '';
      this.id = 0;
      store.state.answer = '';
    }
  },
};
</script>
<template>
  <div class="view-item">
    <div class="add-item-left" v-on:click="openAddQuestion">
    </div>
    <div class="add-item-window" :key="store.state.title">
      <form class="form" v-on:submit.prevent="(e) =>
       {addQuestionFunc(e)}" id="add_dep_form" style="width: 100%;">
        <div style="height: 100vh" :key="this.id">
          <div class="menu" :key="store.state.title">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddQuestion"/>
            <div class="title">
              {{ this.id > 0 ?  $t('FILE_AI.EDIT_QUESTION') : $t('FILE_AI.ADD_QUESTION') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit"
            >
              <div v-if="this.loading === true">
                <unicon fill="white" height="16" width="16" name="spinner-alt"/>
              </div>
              <div v-if="this.loading === false">
                <unicon fill="white" height="16" width="16" name="save"/>
                {{ $t('GENERAL.SAVE') }}
              </div>
            </BaseButton>
          </div>
          <div class="content">

            <div>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.QUESTION_TITLE') }}
                    </div>
                    <div class="custom-input recorder">
                    <input type="text"
                              :id="store.state.title"
                              :value="store.state.title"
                              v-on:change="(e) => store.state.title = e.target.value"
                              :placeholder="$t('TAGS.FIELDS.QUESTION_TITLE')"
                              required
                              maxlength="200"
                    />
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px"
              >
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.ANSWER') }}
                    </div>
                    <div class="custom-input recorder">
                    <textarea type="text"
                              :value="store.state.answer"
                              v-on:change="(e) => store.state.answer = e.target.value"
                              :placeholder="$t('TAGS.FIELDS.ANSWER')"
                              required
                              maxlength="200"
                    />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
</style>
